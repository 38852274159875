body {
  margin: 0;
  padding: 0 !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: rgba(0,0,0,0.13);
  background-clip: padding-box;  
  border: 2px solid transparent;
  border-radius: 4px;
}
::-webkit-scrollbar-thumb:hover {
  background: rgba(0,0,0,0.13);
  background-clip: padding-box;  

}
::-webkit-scrollbar-thumb:active {
  background: rgba(0,0,0,0.32);
  background-clip: padding-box;  
}
::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 4px;
  background-clip: padding-box;
  border: 2px solid transparent;
}
::-webkit-scrollbar-track:hover {
  background: rgba(0,0,0,0.08);
  background-clip: padding-box;  

}
::-webkit-scrollbar-track:active {
  background: rgba(0,0,0,0.13);
  background-clip: padding-box;  
}
::-webkit-scrollbar-corner {
  background: transparent;
}